//Style
import '../scss/style.scss';
import 'slick-carousel/slick/slick.scss';
//import '@fancyapps/fancybox/src/css/core.css';
//import '@fancyapps/fancybox/src/css/thumbs.css';

//Javascript
import jQuery from "jquery";

export {default as $} from 'jquery';
import 'bootstrap';
import SmoothScroll from './smooth-scroll';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {tsParticles} from "tsparticles";
import slick from "slick-carousel/slick/slick";
//require("particles.js");
window.jQuery = jQuery;

//require("@fancyapps/fancybox");

if (!window.liferayedit ) {

    if (window.isHomepage) {}
        gsap.registerPlugin(ScrollTrigger);

        const ss = new SmoothScroll({
            el: document.querySelector("[data-scroll-container]"),
            smooth: window.activeSmooth,
            getDirection: !0
        });
        ss.on("scroll", ScrollTrigger.update), ScrollTrigger.scrollerProxy(document.querySelector("[data-scroll-container]"), {
            scrollTop(e) {
                return arguments.length ? ss.scrollTo(e, 0, 0) : ss.scroll.instance.scroll.y
            },
            getBoundingClientRect: () => ({top: 0, left: 0, width: window.innerWidth, height: window.innerHeight}),
            pinType: document.querySelector("[data-scroll-container]").style.transform ? "transform" : "fixed"
        }), ScrollTrigger.addEventListener("refresh", () => ss.update()), ScrollTrigger.refresh(), ss.on("scroll", e => {
            e.scroll.y > 300 ? document.querySelectorAll("header")[0].classList.add("sticky") : document.querySelectorAll("header")[0].classList.remove("sticky");
            e.scroll.y > 100 && "down" == e.direction ? document.querySelectorAll("header")[0].classList.add("hide") : document.querySelectorAll("header")[0].classList.remove("hide")
        });

    if (window.isHomepage) {
//PARTICLES - HOME
        if (document.getElementById('particles-js') !== null) {
            tsParticles.loadJSON('particles-js', window.particlePath, function () {
            });
        }
        if (document.getElementById('particles-js--e') !== null) {
            tsParticles.loadJSON('particles-js--e', window.particlePath, function () {
            });
        }
    }

//PARTICLES - PAGINA
    if (document.getElementById('particles-js--4') !== null) {
        tsParticles.loadJSON('particles-js--4', window.particlePath, function () {
        });
    }

    if (document.getElementById('particles-js--plus') !== null) {
        tsParticles.loadJSON('particles-js--4', window.particlePath, function () {
        });
    }
    if (document.getElementById('particles-js--intro') !== null) {
        tsParticles.loadJSON('particles-js--intro', window.particlePath, function () {
        });
    }

    if (window.isHomepage) {
        //HOME CAROUSEL
        let homeCarousel = jQuery('.home__carousel').slick({
            arrows: false,
            autoplay: 6000,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        });

        jQuery(".home__carousel-dots a").click(function (e) {
            e.preventDefault();
            jQuery(".home__carousel-dots a").removeClass('active');
            jQuery(this).addClass('active');
            var slideIndex = jQuery(this).index();
            homeCarousel.slick('slickGoTo', parseInt(slideIndex));
        });

        homeCarousel.on('afterChange', function (event, slick, currentSlide, nextSlide) {
            jQuery(".home__carousel-dots a").removeClass('active');
            jQuery(".home__carousel-dots a").eq(currentSlide).addClass('active');
        });

        jQuery('.home__ibox').hover(function () {
            var tar = jQuery(this).find('.home__ibox-content');
            gsap.to(tar, {height: "auto", duration: .5, ease: "power2.inOut"});
        }, function () {
            var tar = jQuery(this).find('.home__ibox-content');
            gsap.to(tar, {height: "0", duration: .5, ease: "power2.inOut"});
        });
    }
}


//NEWS CAROUSEL
let newsCarousel = jQuery('.home__newsCarousel').slick({
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    infinite: false,
});

jQuery('.home__news-next').on('click', function () {
    newsCarousel.slick('slickNext');
});
jQuery('.home__news-prev').on('click', function () {
    newsCarousel.slick('slickPrev');
});

jQuery('.btn--traffic').on('click', function () {
    jQuery(this).toggleClass('on');
    jQuery('.info-traffic').toggleClass('active');
});

if (!window.liferayedit) {

    jQuery('.navbar-toggler').click(function (e) {

        e.preventDefault();


        if (jQuery(this).hasClass('active')) {
            jQuery(this).removeClass('active');
            var TLmenu = new gsap.timeline({
                onStart: () => {

                },
                onComplete: () => {
                    jQuery('.navbar-collapse').removeClass('show');
                }
            });

            TLmenu
                .to(".navbar-collapse", {
                        duration: .5,
                        x: '100%',
                    }
                );
        } else {
            jQuery(this).addClass('active');
            var TLmenu = new gsap.timeline({
                onStart: () => {
                    jQuery('.navbar-collapse').addClass('show');
                },
                onComplete: () => {

                }
            });

            TLmenu
                .to(".navbar-collapse", {
                        duration: .5,
                        x: '0%',
                    }
                );
        }


    });

}


jQuery('.acnav__label').click(function () {
    var label = jQuery(this);
    var parent = label.parent('.has-children');
    var list = label.siblings('.acnav__list');

    if (parent.hasClass('is-open')) {
        list.slideUp('fast');
        parent.removeClass('is-open');
    } else {
        list.slideDown('fast');
        parent.addClass('is-open');
    }
});


window.addEventListener("load", () => {
    jQuery('body').addClass('loaded');
});

window.addEventListener("resize", () => {
    //location.reload();
});


